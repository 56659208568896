@import '../../../../assets/vars.css';

.Testimonials h1 {
  font-size: 2.6vw;
  margin-bottom: 3.15vw;
  text-align: center;
}
.Testimonials {
  margin-bottom: 3.15vw;
  position: relative;
}
/*.Testimonials > article {*/
/*  display: flex;*/
/*}*/
.Testimonials .carousel {
  background-color: rgba(9, 179, 170, 0.1);
  border-radius: 0.72vw;
}
.video {
  padding: 10px;
  text-align: center;
  height: 15vw;
}

.video > div {
  position: relative;
  width: 100% !important;
  height: 100% !important;
  cursor: pointer;
}

.video iframe {
  border-radius: 0.72vw;
}

.video video {
  border-radius: 0.72vw;
  width: 100% !important;
  height: 100% !important;
}

.video img {
  border-radius: 0.72vw;
  width: 100% !important;
  height: 100% !important;
}

.videoTitle {
  position: absolute;
  width: 100%;
  bottom: 10px;
  text-align: center;
  color: lightgrey;
}

.videoModal {
  width: 80%!important;
}

.videoModalTitle {
  font-size: 2.2vw;
  margin-bottom: 10px;
}

.videoModal video {
  width: 100%;
}

@media(--tablet-window) {
  .video {
    height: 50vw !important;
  }
  .Testimonials h1 {
    font-size: 4.6vw;
    line-height: 10vw;
  }
}
@media (--mobile-window) {
  .video {
    height: 50vw !important;
  }
  .Testimonials {
    margin-top: 5.15vw;
  }
  .Testimonials h1 {
    margin-bottom: 6.69vw;
    font-size: 7.82vw;
    line-height: 8.5vw;
  }
}

.anchor {
  position: absolute;
  top: -16vw;
  left: 0;
  width: 100%;
}
@media (--tablet-window) {
  .anchor {
    top: -25vw;
  }
}
@media (--mobile-window) {
  .anchor {
    top: -100px;
  }
}