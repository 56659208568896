@import '../../../assets/vars.css';

.cardsLogin {
  padding: 0 15%;
  margin: 3.15vw 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loginError {
  color: red;
}

.cardsLogin > form {
  width: 300px;
  display: flex;
  flex-direction: column;
  grid-row-gap: 10px;
}

.cardsLogin > form .formRow {
  display: flex;
  align-items: center;
  grid-column-gap: 20px;
}

.cardsLogin > form .formRow label {
  display: flex;
  justify-content: flex-start;
  width: 40%;
}

.cardsLogin > form .formRow input {
  width: 60%;
  border-radius: 0.72vw;
  height: 3.94vw;
  border:  1px solid grey;
  padding: 5px;
}

.cardsList {
  padding: 0 15%;
  margin: 3.15vw 0;
}

.cardsList table {
  width: 100%;
}

.cardsList table th, .cardsList table td {
  padding: 10px;
  border-bottom: 1px solid grey;
  height: 45px;
}
