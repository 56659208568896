@import '../../node_modules/slick-carousel/slick/slick.css';
@import '../../node_modules/slick-carousel/slick/slick-theme.css';

@font-face {
    font-family: 'Open Sans';
    src: local('Open Sans'), url('./fonts/OpenSans-Regular.woff2') format('woff2'),
    url('./fonts/OpenSans-Regular.woff') format('woff'),
    url('./fonts/OpenSans-Regular.ttf') format('truetype');
    unicode-range: U+000-5FF;
}

/*
@font-face {
  font-family: OpenSansBold;
  font-display: block;
  src: url('./fonts/OpenSans-Bold.woff') format('woff');
  font-weight: bold;
}*/

:root {
    --color1: #005ba8;
    --color2: #4bc0b0;
    --color3: #f5222d;
    --color4: #07d140;
    --tablet: 800px;
}

body {
    margin: 0;
    padding: 0;
}

* {
    font-family: 'Open Sans', sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a,
a:focus {
    text-decoration: initial;
}

ul {
    list-style-type: none;
    padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: black;
}

body::-webkit-scrollbar-thumb {
    background-color: white;
    color: black;
}

.ant-slider-handle:focus {
    border-color: var(--color4) !important;
    box-shadow: 0 0 0 0 var(--color4) !important;
}

.ant-slider-handle {
    border: solid 2px var(--color4) !important;
    background-color: var(--color4) !important;
    background-image: url('./icons/index/circle.png');
    background-repeat: no-repeat;
    background-position: -2px -2px;
    background-size: 100%;
    margin-left: -0.63vw;
    margin-top: -0.52vw;
    width: 1.31vw;
    height: 1.31vw;
}

.ant-slider-rail {
    background-color: white;
}

.ant-slider:hover .ant-slider-track {
    background-color: var(--color4);
}

.ant-slider-track,
.ant-slider-track:hover,
.ant-slider-track:focus,
.ant-slider-track:active {
    background-color: var(--color4);
}

.ant-slider-dot-active {
    background-color: var(--color4) !important;
    border-color: var(--color4) !important;
}

.ant-slider-dot {
    border: inherit;
    border-radius: inherit;
    height: 10px;
    width: 2px;
}

.ant-slider-dot:nth-child(2n + 1) {
    height: 10px;
    top: -3px;
}

.ant-slider-dot:nth-child(2n) {
    height: 8px;
    top: -2px;
}

.ant-slider-dot:first-child {
    margin-left: -1px;
    height: 10px;
    top: -3px;
}

.ant-slider-dot:last-child {
    margin-left: -2px;
}

.ant-slider-mark-text:last-child {
    width: 20% !important;
    margin-left: -76px !important;
}

.ant-input-number-handler-wrap {
    display: none;
}

.ant-input,
.ant-input-number {
    font-family: inherit;
    font-variant: inherit;
    -webkit-box-sizing: inherit;
    box-sizing: inherit;
    list-style: none;
    position: relative;
    padding: inherit;
    width: inherit;
    height: inherit;
    font-size: inherit;
    line-height: inherit;
    color: inherit;
    background-color: inherit;
    background-image: inherit;
    -webkit-transition: inherit;
    -o-transition: inherit;
    transition: inherit;
    margin: inherit;
    display: inherit;
    border: inherit;
    border-radius: inherit;
}

.ant-input-number-input {
    border-radius: inherit;
    padding: inherit;
}

.ant-input-focused,
.ant-input-number-focused {
    all: unset;
}

.ant-form-item-label {
    width: 100%;
    height: 26px;
    font-weight: bold;
    text-align: left;
}

.ant-form-item-label label:after {
    display: none;
}

.ant-form-item-required:before {
    display: none;
}

@font-face {
    font-family: 'icomoon';
    src: url('fonts/icomoon.eot?se4pmh');
    src: url('fonts/icomoon.eot?se4pmh#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?se4pmh') format('truetype'),
    url('fonts/icomoon.woff?se4pmh') format('woff'),
    url('fonts/icomoon.svg?se4pmh#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^='icon_'],
[class*=' icon_'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon_Arrow:before {
    content: '\e900';
    color: var(--color4);
}

.moreAbout {
    text-decoration: underline;
    color: black;
    font-size: 1vw;
    line-height: 1.44vw;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    font-family: 'Open Sans', sans-serif, sans-serif !important;
}

.moreAbout:hover {
    color: var(--color1);
}

.ant-modal {
    margin: 0 auto !important;
    width: initial;
}

.ant-modal-footer,
.ant-modal-header {
    display: none;
}

.ant-modal-close {
    right: -0.73vw;
    top: -0.73vw;
    z-index: 10;
    background-color: var(--color1);
    width: 1.47vw;
    height: 1.47vw;
    border-radius: 50%;
}

.ant-modal-close > span {
    width: inherit;
    height: inherit;
    line-height: 1.47vw;
    color: white;
    font-size: 0.85vw;
}

.red {
    color: var(--color3);
}

.ant-modal > div {
    border-radius: 0.52vw !important;
}

.ant-modal-body {
    padding: 4vw 5vw !important;
}

.disabled {
    pointer-events: none !important;
    cursor: initial !important;
    opacity: 0.5;
}

.disabled:hover {
    color: black;
}

@media (max-width: 1400px) {
    .moreAbout {
        font-size: 1vw;
        line-height: 1.44vw;
    }

    .ant-modal-close {
        right: -1vw;
        top: -1vw;
        width: 2.02vw;
        height: 2.02vw;
        border-radius: 50%;
    }

    .ant-modal-close > span {
        line-height: 2vw;
        font-size: 1.15vw;
    }

    .ant-modal > div {
        border-radius: 0.72vw !important;
    }

    .ant-modal-body {
        padding: 5vw 6vw !important;
    }
}

@media (max-width: 900px) {
    .moreAbout {
        font-size: 1.5vw;
        line-height: 2.2vw;
    }

    .ant-modal > div {
        border-radius: 1.25vw !important;
    }

    .ant-modal-close {
        right: -1.73vw;
        top: -1.73vw;
        width: 3.5vw;
        height: 3.5vw;
    }

    .ant-modal-close > span {
        line-height: 3.47vw;
        font-size: 1.85vw;
    }
}

@media (max-width: 460px) {
    .moreAbout {
        font-size: 3.47vw;
        line-height: 4.5vw;
    }

    .ant-modal > div {
        border-radius: 2.17vw !important;
    }

    .ant-modal-close {
        right: -3vw;
        top: -3vw;
        width: 6.08vw;
        height: 6.08vw;
    }

    .ant-modal-close > span {
        line-height: 6.08vw;
        font-size: 3.91vw;
    }
}
